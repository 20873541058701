<template>
  <div>
      <div class="interaction-header">
          <h6 class="interaction-header-title mb-0">
              {{ t(`interactions.event.${data.event}`) }}
              <span class="text-decoration-underline">{{ name }}</span>
          </h6>
          <div class="text-muted text-right">
              {{ formattedDate }}
          </div>
      </div>
          <div class="my-50">
              <div
                v-if="data.additional_data"
                class="interaction-changes"
              >
                  <div class="d-flex gap-2 align-items-center">
                      <div
                          v-if="isImage(data.additional_data.extension) && data.additional_data.url"
                          :style="{ width: '45px', height: '100%', maxHeight: '45px' }"
                          class="cursor-pointer"
                          @click="openImage"
                      >
                          <img
                              :src="data.additional_data.url"
                              :alt="data.additional_data.name"
                              :style="{
                                  width: '100%',
                                  height: '100%',
                                  maxHeight: '45px',
                                  'object-fit': 'cover'
                              }"
                              class="rounded"
                              rounded
                          >
                      </div>
                      <div
                          v-if="isImage(data.additional_data.extension)
                                 && !data.additional_data.url"
                          :style="{ width: '45px', height: '45px', backgroundColor: '#FEF1E1' }"
                          class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                      >
                          <ImageIcon />
                      </div>
                      <div
                          v-if="data.additional_data.extension === 'pdf'"
                          :style="{ width: '45px', height: '45px', backgroundColor: '#FCE4E4' }"
                          class="d-flex align-items-center
                            justify-content-center rounded flex-shrink-0"
                          :class="{
                                'pointer': !!data.additional_data.url,
                              }"
                          @click="handleDownload(
                                  data.additional_data.id,
                                  data.additional_data.name
                                  )"
                      >
                          <Pdf />
                      </div>
                      <div
                          v-if="data.additional_data.extension === 'xlsx'
                          || data.additional_data.extension === 'xls'"
                          :style="{ width: '45px', height: '45px', backgroundColor: '#DCF6E8' }"
                          class="d-flex align-items-center
                            justify-content-center rounded flex-shrink-0"
                          :class="{
                                'pointer': !!data.additional_data.url,
                              }"
                          @click="handleDownload(
                                  data.additional_data.id,
                                  data.additional_data.name
                                  )"
                      >
                          <Xlsx />
                      </div>
                      <div
                          v-if="data.additional_data.extension === 'docx'
                          || data.additional_data.extension === 'doc'"
                          :style="{ width: '45px', height: '45px', backgroundColor: '#ddecff' }"
                          class="d-flex align-items-center
                            justify-content-center rounded flex-shrink-0"
                          :class="{
                                'pointer': !!data.additional_data.url,
                              }"
                          @click="handleDownload(
                                  data.additional_data.id,
                                  data.additional_data.name
                                  )"
                      >
                          <Docx />
                      </div>
                      <div
                          v-if="data.additional_data.extension === 'mp4'
                              || data.additional_data.extension === 'webm'
                              || data.additional_data.extension === 'mov'"
                          :style="{ width: '45px', height: '45px', backgroundColor: '#E8E7FD' }"
                          class="d-flex align-items-center
                            justify-content-center rounded flex-shrink-0"
                          :class="{
                                'pointer': !!data.additional_data.url,
                              }"
                          @click="handleDownload(
                                  data.additional_data.id,
                                  data.additional_data.name
                                  )"
                      >
                          <Movie />
                      </div>
                      <div class="d-flex flex-column">
                          <p
                              class="mb-0 font-weight-bold"
                             :class="{
                                'pointer': !!data.additional_data.url,
                                'text-decoration-underline': !!data.additional_data.url,
                              }"
                              @click="handleDownload(
                                  data.additional_data.id,
                                  data.additional_data.name
                                  )"
                          >
                              {{ data.additional_data.name }}
                          </p>
                          <p class="mb-0">
                              {{ fileSizeConvert(data.additional_data.size) }}
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      <b-modal
          ref="fileModalRef"
          v-model="fileModal"
          size="lg"
          :title="t('interactions.modal.show_file')"
      >
        <template #modal-footer="{}">
          <BButton @click="$refs['fileModalRef'].hide()">
            Закрыть
          </BButton>
        </template>
        <div class="fileModalImages">
          <img
              class="signModal"
              :style="{
                width: '100%',
                height: '100%',
                maxHeight: '50vh',
                'object-fit': 'contain'
              }"
              :src="data.additional_data.url"
          >
        </div>
      </b-modal>
  </div>
</template>

<script>
import {useI18n} from "@/hooks/useI18n";
import {toRefs, ref} from "@vue/composition-api";
import {formatDateShort} from "@/utils/time";
import fileSizeConvert from "@/utils/fileSizeConvert";
import Pdf from "@/assets/images/icons/file-icons/Pdf.vue";
import Xlsx from "@/assets/images/icons/file-icons/Xlsx.vue";
import Movie from "@/assets/images/icons/file-icons/Movie.vue";
import Docx from "@/assets/images/icons/file-icons/Docx.vue";
import ImageIcon from "@/assets/images/icons/file-icons/ImageIcon.vue";
import fileTypeByExt from "@/utils/fileTypeByExt";
import { BButton, BModal } from 'bootstrap-vue';
import {axiosIns} from "@/store/api";

export default {
    components: {
        Docx,
        Movie,
        Xlsx,
        Pdf,
        ImageIcon,
        BButton,
        BModal
    },
  props: {
      data: Object,
  },
  setup(props) {
    const { t } = useI18n();
      const { getFileType } = fileTypeByExt();
      const fileType = (ext) => getFileType(ext);
      const isImage = (ext) => fileType(ext) === 'image';

    const { data } = toRefs(props);
    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    const fileModal = ref(false);
    const openImage = () => {
      fileModal.value = true;
    };

      const handleDownload = async (id, filename) => {
          if (data.value.additional_data.url === null) {
              return;
          }
          await axiosIns.get(`/tools/download-file/${id}`, { headers: { Accept: '*/*' }, responseType: "blob" })
              .then((res) => {
                  const csvURL = window.URL.createObjectURL(res.data);
                  const tempLink = document.createElement('a');
                  tempLink.href = csvURL;
                  tempLink.setAttribute('download', filename);
                  tempLink.click();

                  tempLink.remove();
              })
      };

    return {
        t,
        isImage,
        formattedDate,
        fileSizeConvert,
        openImage,
        fileModal,
        handleDownload
    }
  }
}
</script>
