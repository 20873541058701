<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event }`) }}
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div class="my-50">
      <div class="interaction-changes">
        <div class="interaction-changes-item">
          <i class="interaction-changes-item-title">
            <span v-if="employee === 8">
              {{ t(`interactions.event.employee_attached`) }}
            </span>
            <span v-if="employee === 9">
              {{ t(`interactions.event.employee_detached`) }}
            </span>
          </i>
          <span class="interaction-changes-item-value">
            {{ data.additional_data.employee.firstname }}
            {{ data.additional_data.employee.surname }}
          </span>
        </div>
        <span class="interaction-changes-item-value">
          <template v-if="data.comment">
            <i>{{ data.comment }}</i>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    data: Object,
    employee: Number,
  },
  setup(props) {
    const { t } = useI18n();

    const { data, employee } = toRefs(props);
    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      formattedDate,
      employee,
    };
  },
};
</script>
