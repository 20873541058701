<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ displayName }}
        </p>
        <span class="user-status">
          {{ userRole }}
        </span>
      </div>
      <b-avatar
        size="40"
        :src="avatarUrl"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!displayName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <!--    <b-dropdown-divider />-->

    <b-dropdown-item
      :to="{ name: 'settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>
        {{ t('app.settings') }}
      </span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>
        {{ t('app.logout') }}
      </span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import router from "@/router";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();

    const displayName = computed(() => store.getters['user/userFullName']);
    const avatarUrl = computed(() => store.getters['user/avatar']);
    const userRole = computed(() => store.getters['user/role']?.description);

    const logout = async () => {
      await store.dispatch('user/logout');
      await router.push('/sign-in');
    };

    return {
      t,

      displayName,
      avatarUrl,
      userRole,

      logout,
    };
  },
};
</script>
