<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}:
        <span class="text-decoration-underline">{{ name }}</span>
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div class="my-50">
      <BBadge variant="light-primary">
        {{ position }}
      </BBadge>
      Привязка контакта.
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { BBadge } from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import { formatDateShort } from '@/utils/time';

export default {
  components: {
    BBadge,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const { data } = toRefs(props);
    const { name, position } = data.value.additional_data.contact;
    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      name,
      position,
      formattedDate,
    };
  },
};
</script>
