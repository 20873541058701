<template>
  <div class="navbar-container d-flex content align-items-center">
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <search-bar class="mr-4" />

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <notification-dropdown /> -->
      <dark-toggler class="d-none d-lg-block" />
        <notification-dropdown />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue';
// import NotificationDropdown from '@core/layouts/
// components/app-navbar/components/NotificationDropdown.vue';
import UserDropdown from '@core/layouts/components/app-navbar/components/UserDropdown.vue';
import NotificationDropdown from "@core/layouts/components/app-navbar/components/NotificationDropdown.vue";

export default {
  components: {
      NotificationDropdown,
    BLink,
    BNavbarNav,
    DarkToggler,
    SearchBar,
    // NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
