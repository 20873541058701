<template>
  <layout-vertical>
    <router-view :class="`routerView ${routerContainerClass}`" />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue';
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import {computed} from "@vue/composition-api";
import {useStore} from "@/hooks/useStore";
import {useI18n} from "@/hooks/useI18n";
import Navbar from '../components/Navbar.vue';

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
  computed: {
    routerContainerClass() {
      if (this.$store.state.requests.gridDisplayed) return 'transparent';
      return '';
    },
  },
  setup() {
      const { t } = useI18n();
      const store = useStore();
      const userAccount = computed(() => store.getters['user/account']);
      const userRole = computed(() => store.getters['user/role'])

      return {
          t,
          userAccount,
          userRole
      }
  }
};
</script>

<style lang="sass" scoped>
.routerView
  &.transparent
    background: unset
</style>
