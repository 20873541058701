<template>
<div>
  <div class="interaction-header">
    <h6 class="interaction-header-title mb-0">
      <template v-if="[11, 12].includes(data.event)">
        {{ t(`interactions.event.${entity}_${data.event}`) }}:
      </template>
      <template v-else>
        {{ t(`interactions.event.${data.event}`) }}:
      </template>
      <span class="text-decoration-underline">{{ name }}</span>
    </h6>
    <div class="text-muted text-right">
      {{ formattedDate }}
    </div>
  </div>
  <div class="my-50">
    <div
        v-if="changes"
        class="interaction-changes"
    >
      <template v-for="(value, name) in changes">
        <div
            v-if="
              (typeof value) === 'object' &&
                !(ignoreCoordinates && (name === 'lon' || name === 'lat'))
            "
            :key="name"
            class="interaction-changes-item"
        >
          <span class="interaction-changes-item-value">
              <template v-if="typeof value.old !== 'undefined'">
                <i>с</i>
                {{ value.old.description }}
                <i>на</i>
              </template>
              {{ value.new.description }}
            </span>
        </div>
      </template>
      <span class="interaction-changes-item-value">
          <template v-if="data.comment">
            <i>{{ data.comment }}</i>
          </template>
        </span>
    </div>
  </div>
</div>
</template>

<script>
import {useI18n} from "@/hooks/useI18n";
import {ref, toRefs} from "@vue/composition-api";
import {useStore} from "@/hooks/useStore";
import {formatDateShort} from "@/utils/time";

export default {
  name: 'RoleUpdated',
  props: {
    data: Object,
    entity: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { data, entity } = toRefs(props);
    const { name } = data.value.resource || {};
    const { changes } = data.value.additional_data;

    const typeIdValues = ref();
    const typeWorks = ref();
    const facilityTypes = ref();

    const store = useStore();
    const ignoreCoordinates = Object.keys(changes).includes('address');

    const formatName = (object) => (object.name ? object.name : `${object.firstname || ''} ${object.surname || ''}`).trim();

    // eslint-disable-next-line no-return-await
    const getOptions = async () => await store.dispatch('requests/types');
    // eslint-disable-next-line no-unused-vars, no-return-await
    const getWorkTypes = async () => await store.dispatch('requests/workTypes');
    // eslint-disable-next-line no-return-await
    const getFacilityTypes = async () => await store.dispatch('facilities/getFacilityTypes');

    const getValue = (raw, name) => {
      if (name === 'type_id' && entity.value === 'facility') {
        (async () => {
          facilityTypes.value = (await getFacilityTypes())?.data || [];
        })();

        if (facilityTypes.value) {
          return facilityTypes.value.find((i) => i.id === raw)?.name || '';
        }
        const types = store.state.facilities.facilityTypes;
        return types.find((i) => i.id === raw)?.name || '';
      }

      if (name === 'type_id' && entity.value === 'request') {
        (async () => {
          typeIdValues.value = (await getOptions())?.data || [];
        })();

        if (typeIdValues.value) {
          return typeIdValues.value.find((i) => i.id === raw).name;
        }
      }

      if (name === 'type') {
        return t(['client']).grid[raw].name;
      }

      if (name === 'is_active' || name === 'is_serviceman') {
        return t(['employees']).grid[raw].name;
      }

      if (name === 'priority') {
        return t(['priority']).grid[raw].name;
      }

      if (name === 'work_type_id') {
        (async () => {
          typeWorks.value = (await getWorkTypes())?.data || [];
        })();

        if (typeWorks.value) {
          return typeWorks.value.find((i) => i.id === raw).name;
        }
      }

      if (name === 'client_sign_required') {
        return raw ? 'включена' : 'выключена';
      }

      if (name === 'status_id') {
        return t(['requests']).grid[raw].name;
      }

      const formattedName = raw ? formatName(raw) : undefined;
      if (formattedName) {
        return formattedName;
      }

      if (['deadline_at', 'contract_till'].includes(name)) {
        const date = new Date(raw);
        return formatDateShort(date, { withHours: true, withMinutes: true });
      }
      return raw;
    };

    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      name,
      changes,
      getValue,
      typeIdValues,
      typeWorks,
      ignoreCoordinates,
      formattedDate,
    };
  },
}
</script>
