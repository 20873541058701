<template>
    <div>
        <div class="interaction-header">
            <h6 class="interaction-header-title mb-0">
                {{ t(`interactions.event.${data.event}`) }}
<!--                <span class="text-decoration-underline">{{ name }}</span>-->
            </h6>
            <div class="text-muted text-right">
                {{ formattedDate }}
            </div>
        </div>
        <div class="my-50">
            <div
                    v-if="data.additional_data"
                    class="interaction-changes"
            >
                <div class="d-flex gap-2 align-items-center">
                    <div
                        v-if="isImage(data.additional_data.extension)"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#FEF1E1' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                    >
                        <ImageIcon />
                    </div>
                    <div
                        v-if="data.additional_data.extension === 'pdf'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#FCE4E4' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                    >
                        <Pdf />
                    </div>
                    <div
                        v-if="data.additional_data.extension === 'xlsx'
                          || data.additional_data.extension === 'xls'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#DCF6E8' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                    >
                        <Xlsx />
                    </div>
                    <div
                        v-if="data.additional_data.extension === 'docx'
                          || data.additional_data.extension === 'doc'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#ddecff' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                    >
                        <Docx />
                    </div>
                    <div
                        v-if="data.additional_data.extension === 'mp4'
                              || data.additional_data.extension === 'webm'
                              || data.additional_data.extension === 'mov'"
                        :style="{ width: '45px', height: '45px', backgroundColor: '#E8E7FD' }"
                        class="d-flex align-items-center
                        justify-content-center rounded flex-shrink-0"
                    >
                        <Movie />
                    </div>
                    <div class="d-flex flex-column">
                        <p class="mb-0 font-weight-bold">
                            {{ data.additional_data.name }}
                        </p>
                        <p class="mb-0">
                            {{ fileSizeConvert(data.additional_data.size) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useI18n} from "@/hooks/useI18n";
import {toRefs} from "@vue/composition-api";
import {formatDateShort} from "@/utils/time";
import Pdf from "@/assets/images/icons/file-icons/Pdf.vue";
import Xlsx from "@/assets/images/icons/file-icons/Xlsx.vue";
import Movie from "@/assets/images/icons/file-icons/Movie.vue";
import Docx from "@/assets/images/icons/file-icons/Docx.vue";
import fileTypeByExt from "@/utils/fileTypeByExt";
import ImageIcon from "@/assets/images/icons/file-icons/ImageIcon.vue";
import fileSizeConvert from "@/utils/fileSizeConvert";

export default {
    components: {
        Docx,
        Movie,
        Xlsx,
        Pdf,
        ImageIcon
    },
    props: {
        data: Object,
    },
    setup(props) {
        const { t } = useI18n();

        const { getFileType } = fileTypeByExt();
        const fileType = (ext) => getFileType(ext);
        const isImage = (ext) => fileType(ext) === 'image';

        const { data } = toRefs(props);
        const formattedDate = formatDateShort(
            data.value.date,
            { withHours: true, withMinutes: true }
        );

        return {
            t,
            isImage,
            formattedDate,
            fileSizeConvert
        }
    }
}
</script>
