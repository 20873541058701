var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"interaction-header"},[_c('h6',{staticClass:"interaction-header-title mb-0"},[_vm._v(" "+_vm._s(_vm.t(("interactions.event." + (_vm.data.event))))+" "),_c('span',{staticClass:"text-decoration-underline"},[_vm._v(_vm._s(_vm.name))])]),_c('div',{staticClass:"text-muted text-right"},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")])]),_c('div',{staticClass:"my-50"},[(_vm.data.additional_data)?_c('div',{staticClass:"interaction-changes"},[_c('div',{staticClass:"d-flex gap-2 align-items-center"},[(_vm.isImage(_vm.data.additional_data.extension) && _vm.data.additional_data.url)?_c('div',{staticClass:"cursor-pointer",style:({ width: '45px', height: '100%', maxHeight: '45px' }),on:{"click":_vm.openImage}},[_c('img',{staticClass:"rounded",style:({
                                width: '100%',
                                height: '100%',
                                maxHeight: '45px',
                                'object-fit': 'cover'
                            }),attrs:{"src":_vm.data.additional_data.url,"alt":_vm.data.additional_data.name,"rounded":""}})]):_vm._e(),(_vm.isImage(_vm.data.additional_data.extension)
                               && !_vm.data.additional_data.url)?_c('div',{staticClass:"d-flex align-items-center\n                      justify-content-center rounded flex-shrink-0",style:({ width: '45px', height: '45px', backgroundColor: '#FEF1E1' })},[_c('ImageIcon')],1):_vm._e(),(_vm.data.additional_data.extension === 'pdf')?_c('div',{staticClass:"d-flex align-items-center\n                          justify-content-center rounded flex-shrink-0",class:{
                              'pointer': !!_vm.data.additional_data.url,
                            },style:({ width: '45px', height: '45px', backgroundColor: '#FCE4E4' }),on:{"click":function($event){return _vm.handleDownload(
                                _vm.data.additional_data.id,
                                _vm.data.additional_data.name
                                )}}},[_c('Pdf')],1):_vm._e(),(_vm.data.additional_data.extension === 'xlsx'
                        || _vm.data.additional_data.extension === 'xls')?_c('div',{staticClass:"d-flex align-items-center\n                          justify-content-center rounded flex-shrink-0",class:{
                              'pointer': !!_vm.data.additional_data.url,
                            },style:({ width: '45px', height: '45px', backgroundColor: '#DCF6E8' }),on:{"click":function($event){return _vm.handleDownload(
                                _vm.data.additional_data.id,
                                _vm.data.additional_data.name
                                )}}},[_c('Xlsx')],1):_vm._e(),(_vm.data.additional_data.extension === 'docx'
                        || _vm.data.additional_data.extension === 'doc')?_c('div',{staticClass:"d-flex align-items-center\n                          justify-content-center rounded flex-shrink-0",class:{
                              'pointer': !!_vm.data.additional_data.url,
                            },style:({ width: '45px', height: '45px', backgroundColor: '#ddecff' }),on:{"click":function($event){return _vm.handleDownload(
                                _vm.data.additional_data.id,
                                _vm.data.additional_data.name
                                )}}},[_c('Docx')],1):_vm._e(),(_vm.data.additional_data.extension === 'mp4'
                            || _vm.data.additional_data.extension === 'webm'
                            || _vm.data.additional_data.extension === 'mov')?_c('div',{staticClass:"d-flex align-items-center\n                          justify-content-center rounded flex-shrink-0",class:{
                              'pointer': !!_vm.data.additional_data.url,
                            },style:({ width: '45px', height: '45px', backgroundColor: '#E8E7FD' }),on:{"click":function($event){return _vm.handleDownload(
                                _vm.data.additional_data.id,
                                _vm.data.additional_data.name
                                )}}},[_c('Movie')],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-0 font-weight-bold",class:{
                              'pointer': !!_vm.data.additional_data.url,
                              'text-decoration-underline': !!_vm.data.additional_data.url,
                            },on:{"click":function($event){return _vm.handleDownload(
                                _vm.data.additional_data.id,
                                _vm.data.additional_data.name
                                )}}},[_vm._v(" "+_vm._s(_vm.data.additional_data.name)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.fileSizeConvert(_vm.data.additional_data.size))+" ")])])])]):_vm._e()]),_c('b-modal',{ref:"fileModalRef",attrs:{"size":"lg","title":_vm.t('interactions.modal.show_file')},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){return [_c('BButton',{on:{"click":function($event){return _vm.$refs['fileModalRef'].hide()}}},[_vm._v(" Закрыть ")])]}}]),model:{value:(_vm.fileModal),callback:function ($$v) {_vm.fileModal=$$v},expression:"fileModal"}},[_c('div',{staticClass:"fileModalImages"},[_c('img',{staticClass:"signModal",style:({
              width: '100%',
              height: '100%',
              maxHeight: '50vh',
              'object-fit': 'contain'
            }),attrs:{"src":_vm.data.additional_data.url}})])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }