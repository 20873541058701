<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <b-alert
      v-if="userRole && userRole.name === 'admin' && value / (max / 100) > 90"
      :variant="value / (max / 100) >= 100 ? 'danger' : 'warning'"
      show
      class="p-1"
    >
      <span>
        {{ value / (max / 100) >= 100
        ? t('settings.file-storage.change-title-error')
        : t('settings.file-storage.change-title-warning') }}
      </span>
      <div class="mt-1">
        <b-link
          class="alert-link cursor-pointer"
          @click="toTariffs"
        >
          {{ t('settings.file-storage.change-text') }}
          <b-spinner
            v-if="resendingVerification"
            small
          />
        </b-link>
      </div>
    </b-alert>
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import { BAlert } from 'bootstrap-vue';
import {computed} from "@vue/composition-api";
import {useStore} from "@/hooks/useStore";
import {useI18n} from "@/hooks/useI18n";
import { useRouter } from '@/hooks/useRouter';

export default {
  components: {
    AppBreadcrumb,
    BAlert
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig();
    const { t } = useI18n();
    const store = useStore();
    const userAccount = computed(() => store.getters['user/account']);
    const userRole = computed(() => store.getters['user/role']);
    const router = useRouter();

    const max = userAccount.value == null ? 0 : userAccount.value.file_tariff.max_size;
    const value = userAccount.value == null ? 0 : userAccount.value.storage_size;

    const toTariffs = () => {
        router.redirect('/settings/file-store');
    };

    return {
      routerTransition,
      contentWidth,
      t,
      userAccount,
      userRole,
      toTariffs,
      max,
      value
    };
  },
};
</script>

<style>

</style>
