<template>
  <ul>
    <template
      v-for="item in items"
    >
      <component
        :is="resolveNavItemComponent(item)"
        v-if="getItemPermission(item.route)"
        :key="item.header || item.title"
        :item="item"
      />
    </template>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils';
import { provide, ref } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import VerticalNavMenuHeader from '../vertical-nav-menu-header';
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue';
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue';

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]));

    const store = useStore();
    const getItemPermission = (route) => {
      const name = route;
      // if (name === 'checklists') name = 'check_lists';
      // if (name === 'scheduler') name = 'schedule';
      // if (name === 'settings') return true;

      if (['checklists', 'scheduler', 'settings'].includes(name)) {
        return true;
      }

      if (['clients'].includes(name)) {
          return store.getters['user/permission'](`${name}:read`) && store.getters['user/account'].show_clients;
      }

      return store.getters['user/permission'](`${name}:read`);
    };

    return {
      resolveNavItemComponent,
      getItemPermission,
    };
  },
};
</script>
