<template>
    <b-nav-item-dropdown
        class="dropdown-notification mr-25"
        menu-class="dropdown-menu-media"
        right
    >
        <template #button-content>
            <feather-icon
                :badge="newItems"
                badge-classes="bg-danger"
                class="text-body"
                icon="BellIcon"
                size="21"
            />
        </template>

        <!-- Header -->
        <li class="dropdown-menu-header">
            <div class="dropdown-header d-flex">
                <h4 class="notification-title mb-0 mr-auto">
                    {{ t('notifications.title') }}
                </h4>
                <b-badge
                    pill
                    variant="light-primary"
                >
                    {{ t('notifications.new', { count: newItems }) }}
                </b-badge>
            </div>
        </li>

        <!-- Notifications -->
        <div class="scrollable-container overflow-y-scroll media-list scroll-area">
            <b-link
                v-for="notification in notifications"
                :key="notification.id"
                @click="clickAction(notification)"
            >
                <b-media>
                    <template #aside>
                        <b-avatar
                            size="32"
                            :src="notification.avatar"
                            :text="notification.title"
                            :variant="notification.is_read ? 'secondary' : notification.color"
>
                            <feather-icon
                                :icon="notificationHelper(notification.entity_name)"
                                size="22"
                            />
                        </b-avatar>
                    </template>
                    <p class="media-heading">
            <span
            class="font-weight-bolder"
            :class="{ 'text-muted': notification.is_read }"
            >
              {{ notification.title }}
            </span>
                    </p>
                    <small
                        class="notification-text"
                        :class="{ 'text-muted': notification.is_read }"
                        v-html="notification.body"
                    />
                </b-media>
            </b-link>
                        <ScrollWatcher @scrolled="scrollNotificationsToEnd" />
        </div>

        <!-- Cart Footer -->
        <li class="dropdown-menu-footer">
<b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="newItems < 1"
            block
            @click="readAllNotifications"
        >
{{ t('notifications.read_all') }}
</b-button>
        </li>
    </b-nav-item-dropdown>
</template>

<script>
import {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    BButton,
    // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from "@vue/composition-api";
import { useStore } from "@/hooks/useStore";
import ScrollWatcher from "@/components/scrollWatcher/ScrollWatcher.vue";
import { useI18n } from "@/hooks/useI18n";
import {notificationHelper} from "@/utils/notifications";

export default {
    components: {
        ScrollWatcher,
        BNavItemDropdown,
        BBadge,
        BMedia,
        BLink,
        BAvatar,
        BButton,
        // BFormCheckbox,
    },
    directives: {
        Ripple,
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();

        const pagination = ref({
            page: 1,
            perPage: 10,
        });
        const totalPages = ref(0);
        const totalItems = ref(null);
        const newItems = ref(null);

        const notifications = ref([]);

        const getNotifications = async () => {
            const {
                result,
                data,
            } = await store.dispatch('getNotifications', { ...pagination.value });
            if (result) {
                totalPages.value = data.last_page;
                totalItems.value = data.total;
                newItems.value = data.unread_count;
                notifications.value.push(...data.data);
            }
        };
        getNotifications();

        setInterval(() => {
            pagination.value.page = 1;
            notifications.value = [];
            getNotifications();
        }, 60000);

        const scrollNotificationsToEnd = async () => {
            if (pagination.value.page < totalPages.value) {
                // eslint-disable-next-line radix
                pagination.value.page = parseInt(pagination.value.page) + 1;
                getNotifications();
            }
        };

        const readAllNotifications = async () => {
            const {
                result,
            } = await store.dispatch('readAllNotifications');
            if (result) {
                pagination.value.page = 1;
                notifications.value = [];
                getNotifications();
            }
        };

        const clickAction = async (notification) => {
            if (!notification.is_read) {
                const {
                    result,
                    data,
                } = await store.dispatch('readNotification', {id: notification.id});
                if (result) {
                    newItems.value = data.unread_count;
                    // eslint-disable-next-line array-callback-return
                    notifications.value = notifications.value.map((el) => {
                        if (el.id === notification.id) {
                            // eslint-disable-next-line no-param-reassign
                            el.is_read = true;
                        }
                        return el;
                    });
                }
            }

            if (notification.additional_data !== null
                && 'action_url' in notification.additional_data) {
                window.open(notification.additional_data.action_url, '_self').focus();
            }
        };

        return {
            t,
            notifications,
            totalItems,
            newItems,
            scrollNotificationsToEnd,
            readAllNotifications,
            notificationHelper,
            clickAction,
            getNotifications,
        }
    },
}
</script>

<style>
</style>
