<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}
        <!-- {{ data. }} -->
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div>
      Удаленный контакт можно восстановить в течение 30 дней. <a href="#">Восстановить контакт.</a>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const { data } = toRefs(props);

    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      formattedDate,
    };
  },
};
</script>
