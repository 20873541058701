<template>
  <li class="search-bar nav-item nav-search">
    <!-- Icon -->
    <a
      href="javascript:void(0)"
      class="nav-link nav-link-search"
      @click="showSearchBar = true"
    >
      <feather-icon
        icon="SearchIcon"
        size="21"
      />
    </a>

    <!-- Input -->
    <div
      class="search-input"
      :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon icon="SearchIcon" />
      </div>
      <b-form-input
        v-if="showSearchBar"
        v-model="searchQuery"
        placeholder="Поиск"
        autofocus
        autocomplete="off"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.esc="showSearchBar = false; searchQuery = null"
        @keyup.enter="suggestionSelected"
        @blur.stop="showSearchBar = false; searchQuery = null"
      />
      <div
        class="search-input-close"
        @click="showSearchBar = false; searchQuery = null"
      >
        <feather-icon icon="XIcon" />
      </div>

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="search-list search-list-main scroll-area overflow-hidden"
        :class="{'show': searchQuery}"
        tagname="ul"
      >
        <template
          v-for="(group, groupName) in results"
        >
          <p
            v-if="group.length"
            :key="`${groupName}_`"
            class="suggestion-group-title"
          >
            {{ t(`search.group.${groupName}`) }}
          </p>
          <ul
            v-if="group.length"
            :key="groupName"
            class="search-group"
          >
            <li
              v-for="(item, index) in group"
              :key="item.id"
              @mousedown.prevent="select"
            >
              <router-link
                :is="groupLink(groupName) ? 'router-link' : 'div'"
                :to="`/${groupLink(groupName)}/${item.id}`"
                class="search-group-link"
                :class="{
                  'suggestion-current-selected': currentSelected === `${groupName}.${index}`
                }"
              >
                <People
                  v-if="groupItemComponent(groupName) === 'people'"
                  :data="item"
                />
                <Base
                  v-if="groupItemComponent(groupName) === 'base'"
                  :data="item"
                />
                <Contact
                  v-if="groupItemComponent(groupName) === 'contact'"
                  :data="item"
                />
              </router-link>
            </li>
          </ul>
        </template>
      </vue-perfect-scrollbar>
    </div>
  </li>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import { ref, watch } from '@vue/composition-api';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { title } from '@core/utils/filter';
import { useStore } from '@/hooks/useStore';
import { useI18n } from '@/hooks/useI18n';
import People from './searchCards/People.vue';
import Base from './searchCards/Base.vue';
import Contact from './searchCards/Contact.vue';

export default {
  components: {
    BFormInput,
    VuePerfectScrollbar,
    People,
    Base,
    Contact,
  },
  setup() {
    const showSearchBar = ref(false);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    };

    const { t } = useI18n();
    const store = useStore();
    const results = ref({});
    const search = async (query) => {
      if (!query || query.length < 3) {
        results.value = {};
      } else {
        const { result, data } = await store.dispatch('app/globalSearch', query);
        if (result) {
          results.value = data;
        }
      }
    };

    const currentSelected = ref('');
    let timer = null;
    const searchQuery = ref(null);
    store.commit('app/TOGGLE_OVERLAY', true);
    watch(searchQuery, (val) => {
      store.commit('app/TOGGLE_OVERLAY', Boolean(val));

      if (timer) clearTimeout(timer);
      timer = setTimeout(() => { search(val); }, 300);
    }, { immediate: true });

    const groupLink = (name) => {
      if (name === 'employees') return 'employee';
      if (name === 'facilities') return 'facility';
      if (['equipments', 'contacts', 'users'].includes(name)) return false;
      return name;
    };
    const groupItemComponent = (name) => {
      if (['employees', 'users', 'clients'].includes(name)) {
        return 'people';
      }
      if (name === 'contacts') return 'contact';
      return 'base';
    };

    const select = () => {
      // eslint-disable-next-line prefer-arrow-callback, func-names
      setTimeout(function () {
        searchQuery.value = null;
        showSearchBar.value = false;
      }, 150);
    };

    return {
      showSearchBar,
      perfectScrollbarSettings,
      title,
      searchQuery,
      results,
      t,
      groupLink,
      groupItemComponent,
      currentSelected,
      select,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.search-bar {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {

}

.search-list-main {
  padding-top: 16px;
  padding-bottom: 16px;
}
.search-group {
  margin: 12px 0;
}
.search-group-link {
  padding: 12px !important;
  display: block;
  transition: background-color .2s;
}
.search-group-link:hover {
  background-color: $body-bg;
  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
