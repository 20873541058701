<template>
  <BSidebar
    v-model="visible"
    bg-variant="white"
    no-header
    right
    backdrop
    shadow
    class="position-relative interaction-sidebar"
    :width="currentBreakPoint === 'xl' ? '40%' : '100%'"
  >
    <div class="sidebar-header d-flex justify-content-between p-1 bg-">
      <div class="title">
          {{ t("interactions.name") }}
      </div>
      <div class="action">
        <feather-icon
          icon="XIcon"
          size="18"
          @click="visible = false"
        />
      </div>
    </div>
    <b-overlay
      variant="transparent"
      :show="buzy"
    >
      <div class="interaction-sidebar__content">
        <app-timeline>
          <InteractionItem
            v-for="item in list"
            :key="item.id"
            :data="item"
          />
        </app-timeline>
      </div>
    </b-overlay>
  </BSidebar>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import { BSidebar, BOverlay } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import {useI18n} from "@/hooks/useI18n";
import InteractionItem from './InteractionItem.vue';

export default {
  name: 'InteractionSidebar',
  components: {
    AppTimeline,
    BSidebar,
    BOverlay,
    InteractionItem,
  },
  props: {
    value: Boolean,
  },
  setup() {
    // console.clear();
      const { t } = useI18n();
    const store = useStore();
    const visible = computed({
      get() {
        return store.state.interactions.visible;
      },
      set(value) {
        if (!value) {
          store.commit('interactions/close');
        }
      },
    });

    const buzy = computed(() => store.state.interactions.buzy);
    const list = computed(() => store.state.interactions.list);
    const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint']);

    return {
        t,
      visible,
      buzy,
      list,
      currentBreakPoint,
    };
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/scss/utils';

.interaction-sidebar {
  &__content {
    padding: 22px 30px;
  }
  .sidebar-header {
    background-color: $body-bg;
  }
  .action {
    cursor: pointer;
  }
}

@include dark-theme {
  .interaction-sidebar {
    .sidebar-header {
      background-color: $theme-dark-body-bg;
    }
  }
}
</style>
