<template>
  <b-col
    class="content-header-right text-md-right mb-1"
    md="3"
    cols="12"
  >
    <div
      v-if="enabled"
      class="d-flex align-items-center justify-content-end position-relative client-interaction"
      @click="open"
    >
      <div class="mr-1">
        <!-- <FeatherIcon
          :badge="interactionUnreadCount || null"
          badge-classes="bg-danger"
          icon="ListIcon"
        /> -->
      </div>
      <span>
          {{ t("interactions.name") }}
      </span>
    </div>
    <InteractionSidebar />
  </b-col>
</template>

<script>
import { watch, ref } from '@vue/composition-api';
import { BCol } from 'bootstrap-vue';
import { useStore } from '@/hooks/useStore';
import { useRouter } from '@/@core/utils/utils';
import InteractionSidebar from '@/components/interactions/InteractionsSidebar.vue';
import {useI18n} from "@/hooks/useI18n";

export default {
  components: {
    BCol,
    InteractionSidebar,
  },
  setup() {
      const { t } = useI18n();
    const store = useStore();
    const { route } = useRouter();

    const open = () => store.dispatch('interactions/open');
    const enabled = ref(false);

    watch(route, (value) => {
      const meta = value?.meta?.interactions;
      if (meta) {
        const id = value.params[meta.idParam];
        store.commit('interactions/setEntity', { entity: meta.entity, entityId: id });
        enabled.value = true;
      } else {
        store.commit('interactions/clear');
        enabled.value = false;
      }
    }, { immediate: true });

    return {
        t,
      open,
      enabled,
    };
  },
};
</script>
