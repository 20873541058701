<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}
        <!-- <span class="text-decoration-underline">{{ name }}</span> -->
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div class="my-50">
      <div
        v-if="changes"
        class="interaction-changes"
      >
        <template v-for="(value, name) in changes">
          <div
            v-if="
              (typeof value) === 'object' &&
                !(ignoreCoordinates && (name === 'lon' || name === 'lat'))
            "
            :key="name"
            class="interaction-changes-item"
          >
            <i class="interaction-changes-item-title">
              Перемещена с объекта
            </i>
            <span class="interaction-changes-item-value">
              {{ getValue(value.old, name) }}
              <!-- <i>на</i>
              {{ getValue(value.new, name) }} -->
            </span>
          </div>
        </template>
        <span class="interaction-changes-item-value">
          <template v-if="data.comment">
            <i>{{ data.comment }}</i>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    data: Object,
    entity: String,
  },
  setup(props) {
    const { t } = useI18n();
    const { data, entity } = toRefs(props);
    const { name } = '';
    const { changes } = data.value.additional_data || {};

    const store = useStore();
    const ignoreCoordinates = (Object.keys(changes || {})).includes('address');

    const getValue = (raw, name) => {
      if (name === 'type_id' && entity.value === 'facility') {
        const types = store.state.facilities.facilityTypes;
        return types.find((i) => i.id === raw)?.name || '';
      }
      return raw;
    };

    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      name,
      changes,
      getValue,
      ignoreCoordinates,
      formattedDate,
    };
  },
};
</script>
