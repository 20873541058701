<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div
        v-if="data.text !== '-' && data.event !== 11"
        class="text-muted"
    >
      {{ data.text }}
      <div style="padding: 30px 0 30px 0;">
        <img
            v-if="data.resource.client_sign"
            :src="sign()"
            class="sign"
            @click="openImage"
        >
      </div>
    </div>
    <span class="interaction-changes-item-value">
      <template v-if="data.comment">
        <i>{{ data.comment }}</i>
      </template>
    </span>

    <b-modal
        ref="fileModalRef"
        v-model="fileModal"
        size="lg"
        :title="t('requests.modal.client_sign')"
    >
      <template #modal-footer="{}">
        <BButton @click="$refs['fileModalRef'].hide()">
          Закрыть
        </BButton>
      </template>
      <div class="fileModalImages">
        <img
            v-if="data.resource.client_sign"
            class="signModal"
            :src="sign()"
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import {useI18n} from "@/hooks/useI18n";
import {ref, toRefs} from "@vue/composition-api";
import { BButton, BModal } from 'bootstrap-vue';
import {formatDateShort} from "@/utils/time";

export default {
  components: {
    BModal,
    BButton,
  },
  props: {
    data: Object,
    employee: Number,
  },
  setup(props) {
    const { t } = useI18n();

    const { data } = toRefs(props);
    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    const sign = () => (data.value.resource.client_sign.includes(';base64')
          ? data.value.resource.client_sign
          : `data:image/png;base64,${data.value.resource.client_sign}`);

    const fileModal = ref(false);
    const openImage = () => {
      fileModal.value = true;
    };

    return {
      t,
      formattedDate,
      openImage,
      fileModal,
      sign,
    };
  }
}
</script>
<style scoped lang="scss">
.fileModalImages {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sign {
  cursor: pointer;
  max-width: 300px;
  @media (max-width: 600px) {
    max-width: 80%;
  }
}
.signModal {
  max-width: 750px;
  @media (max-width: 600px) {
    max-width: 100%;
  }
}
</style>
