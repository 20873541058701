<template>
  <div
      class="user"
      @click="
      $router.push({ name: 'employee-view', params: { employeeId: data.id } });"
  >
    <div class="user-avatar">
      <BAvatar
        :src="data.avatar"
        size="38px"
      />
    </div>
    <div class="user-body">
      <div class="user-body-name">
        {{ formattedName }}
      </div>
      <div class="user-body-role">
        {{ role }}
      </div>
    </div>
    <span class="interaction-changes-item-value">
      <template v-if="data.comment">
        <i>{{ data.comment }}</i>
      </template>
    </span>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { BAvatar } from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
  },
  props: {
    data: Object,
  },
  setup(props) {
    const { data } = toRefs(props);
    const { firstname, surname, patronymic } = data.value;
    const formattedName = [firstname, surname, patronymic].join(' ');

    const role = data.value?.role?.description;

    return {
      formattedName,
      role,
    };
  },
};
</script>

<style lang="sass" scoped>
.user
  display: flex
  align-items: center
  gap: 8px
  margin: 10px 0
  cursor: pointer
  &-body
    &-name
      font-weight: 500
      font-size: 14px
      line-height: 23px
</style>
