<template>
  <div class="search-group-base">
    <div>
      <div>
        {{ formatName(data) }}
      </div>
      <div
        v-if="!data.title && !data.name && !data.firstname && !data.surname && !data.patronymic"
        class="text-muted"
      >
        Без названия
      </div>
      <div
        v-if="data.number"
        class="text-muted"
      >
        #{{ data.number }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: Object,
  },
  setup() {
    const formatName = (obj) => obj.name || obj.title || [obj.firstname, obj.surname, obj.patronymic].join(' ');

    return {
      formatName,
    };
  },
};
</script>

<style lang="sass" scoped>
.search-group-base
  display: flex
  align-items: center
  gap: 12px
</style>
