<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      { 'expanded': !isVerticalMenuCollapsed || (isVerticalMenuCollapsed && isMouseHovered) },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light'
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded mb-1">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <Logo
            :small="isVerticalMenuCollapsed && !isMouseHovered"
            class="navbar-brand"
            state="secondary"
          />
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
        <li
                class="nav-item nav-toggle"
        >
            <b-link
                    class="nav-link documentation-nav-link d-flex"
                    @click="showDocumentation"
            >
                <feather-icon
                        icon="HelpCircleIcon"
                        size="20"
                        class="d-xl-block collapse-toggle-icon"
                />
                <span class="menu-title text-truncate">{{ t('documentation.sidebar.title') }}</span>
            </b-link>
        </li>
      <!-- Toggler Button -->
      <li
        v-if="showCollapseButton"
        class="nav-item nav-toggle"
      >
        <b-link
          class="nav-link modern-nav-toggle d-flex"
          @click="toggleCollapsed"
        >
          <feather-icon
            :icon="collapseTogglerIconFeather"
            size="20"
            class="d-none d-xl-block collapse-toggle-icon"
          />
          <span>{{ isVerticalMenuCollapsed ? 'Развернуть' : 'Свернуть' }}</span>
        </b-link>
      </li>
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
      <b-modal
              v-model="showDocumentanionModal"
              centered
              cancel-variant="outline-secondary"
              ok-variant="primary"
              :title="t('documentation.modal.title')"
              :ok-title="t('documentation.modal.ok')"
              :cancel-title="t('documentation.modal.cancel')"
              @ok="showDocumentation"
      >
          <h5>{{ t('documentation.modal.body') }}</h5>
      </b-modal>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { provide, computed, ref } from '@vue/composition-api';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import Logo from '@core/layouts/components/Logo.vue';
import {
    BLink,
    BModal,
} from 'bootstrap-vue';
import navMenuItems from '@/navigation/vertical';
import { useStore } from '@/hooks/useStore';
import { useI18n } from "@/hooks/useI18n";
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue';
import useVerticalNavMenu from './useVerticalNavMenu';

export default {
  components: {
      BModal,
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    Logo,
    BLink,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const { t } = useI18n();
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
        isFirstLoad,
      collapseTogglerIcon,
      toggleCollapsed,
        toggleFirstLoad,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

      const showDocumentanionModal = ref(isFirstLoad.value);
      toggleFirstLoad();

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'ChevronRightIcon' : 'ChevronLeftIcon'));

    const breakpoint = computed(() => store.getters['app/currentBreakPoint']);
    const showCollapseButton = computed(() => breakpoint.value === 'xl');

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    const showDocumentation = () => {
        window.open('https://docs.servi-fsm.ru', '_blank').focus();
    };
    return {
        t,
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,
        showDocumentation,
        showDocumentanionModal,

      breakpoint,
      showCollapseButton,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
