export const notificationHelper = (value) => {
    switch (value) {
        case 'requests':
            return 'DollarSignIcon'
        case 'facilities':
            return 'FolderIcon'
        case 'employees':
            return 'UsersIcon'
        default:
            return 'BellIcon'
    }
}
