<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}:
        <span class="text-decoration-underline">{{ name }}</span>
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div class="my-50">
      <div
        v-if="changes"
        class="interaction-changes"
      >
        <template v-for="(value, name) in changes">
          <div
            v-if="(typeof value) === 'object'"
            :key="name"
            class="interaction-changes-item"
          >
            <i class="interaction-changes-item-title">
              {{ t(`interactions.field.contact.${name}`) }}
            </i>
            <span class="interaction-changes-item-value">
              <i>с</i> {{ value.old }} <i>на</i> {{ value.new }}
            </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const { data } = toRefs(props);
    const { name, position } = data.value.additional_data?.contact || {};
    const { changes } = data.value.additional_data;

    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    return {
      t,
      name,
      position,
      changes,
      formattedDate,
    };
  },
};
</script>
