<template>
  <div class="search-group-people">
    <b-avatar :src="data.avatar" />
    <div>
      <div>{{ formatName(data) }}</div>
      <div class="text-muted">
        {{ data.position }}
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue';

export default {
  components: {
    BAvatar,
  },
  props: {
    data: Object,
  },
  setup() {
    const formatName = (obj) => obj.name || [obj.firstname, obj.surname, obj.patronymic].join(' ');

    return {
      formatName,
    };
  },
};
</script>

<style lang="sass" scoped>
.search-group-people
  display: flex
  align-items: center
  gap: 12px
</style>
