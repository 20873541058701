<template>
  <div>
    <div class="interaction-header">
      <h6 class="interaction-header-title mb-0">
        {{ t(`interactions.event.${data.event}`) }}
      </h6>
      <div class="text-muted text-right">
        {{ formattedDate }}
      </div>
    </div>
    <div class="text-muted">
      <span class="interaction-changes-item-value">
              <template v-if="!Array.isArray(data.additional_data)">
                <template v-if="typeof data.additional_data.deadline_at.old !== 'undefined'">
                  <i>с</i>
                  {{ formattedDeadline(data.additional_data.deadline_at.old) }}
                  <i>на</i>
                </template>
              </template>
              {{ formattedDeadline(data.additional_data.deadline_at.new) }}
            </span>
    </div>
    <div
        v-if="typeof data.initiator.name !== 'undefined'"
        class="text-muted"
    >
      {{ t('requests.event.initiator') + ': ' + data.initiator.name }}
    </div>
    <div class="interaction-changes-item-value">
      <template v-if="data.comment">
        <i>{{ data.comment }}</i>
      </template>
    </div>
  </div>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import { formatDateShort } from '@/utils/time';

export default {
  props: {
    data: Object,
  },
  setup(props) {
    const { t } = useI18n();

    const { data } = toRefs(props);
    const formattedDate = formatDateShort(data.value.date, { withHours: true, withMinutes: true });

    const formattedDeadline = (value) => formatDateShort(
        `${value.split(' ').join('T')}.000000Z`,
        { withHours: true, withMinutes: true }
    );

    return {
      t,
      formattedDate,
      formattedDeadline,
    };
  },
};
</script>
