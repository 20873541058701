<template>
  <app-timeline-item
    class="interaction"
    :variant="variant"
  >
    <ContactDetached
      v-if="eventType === 'CONTACT_DETACHED'"
      :data="data"
    />
    <ContactAttached
      v-if="eventType === 'CONTACT_ATTACHED'"
      :data="data"
    />
    <ContactUpdated
      v-if="eventType === 'CONTACT_UPDATED'"
      :data="data"
    />
    <EntityUpdated
      v-if="eventType === 'ENTITY_UPDATED'"
      :data="data"
      :entity="data.type"
    />
    <MoveRequest
      v-if="eventType === 'MOVE_REQUEST'"
      :data="data"
    />
    <EmployeeUpdated
      v-if="eventType === 'EMPLOYEE_UPDATED'"
      :data="data"
      :employee="data.event"
    />
    <RoleUpdated
        v-if="eventType === 'ROLE_UPDATED'"
        :data="data"
        :employee="data.event"
    />
    <ClientAcceptedJob
        v-if="eventType === 'CLIENT_ACCEPTED_JOB'"
        :data="data"
        :employee="data.event"
    />
    <ChangePrice
        v-if="eventType === 'CHANGE_PRICE'"
        :data="data"
    />
    <RequestClose
        v-if="eventType === 'REQUEST_CLOSE'"
        :data="data"
    />
    <RequestReschedule
        v-if="eventType === 'REQUEST_RESCHEDULE'"
        :data="data"
    />
    <AddedFile
        v-if="eventType === 'FILE_ATTACHED'"
        :data="data"
    />
    <RemovedFile
        v-if="eventType === 'FILE_DETACHED'"
        :data="data"
    />
    <EmptyEvent
      v-if="!eventType"
      :data="data"
    />

    <InteractionItemUser
      v-if="data.account && data.account.id"
      :data="data.account"
    />
  </app-timeline-item>
</template>

<script>
import { toRefs } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import AppTimelineItem from '@/@core/components/app-timeline/AppTimelineItem.vue';
import ClientAcceptedJob from '@/components/interactions/types/ClientAcceptedJob.vue';
import RoleUpdated from '@/components/interactions/types/RoleUpdated.vue';
import RequestClose from "@/components/interactions/types/RequestClose.vue";
import RequestReschedule from "@/components/interactions/types/RequestReschedule.vue";
import AddedFile from "@/components/interactions/types/AddedFile.vue";
import RemovedFile from "@/components/interactions/types/RemovedFile.vue";
import ContactDetached from './types/ContactDetached.vue';
import InteractionItemUser from './InteractionItemUser.vue';
import ContactAttached from './types/ContactAttached.vue';
import ContactUpdated from './types/ContactUpdated.vue';
import EntityUpdated from './types/EntityUpdated.vue';
import MoveRequest from './types/MoveRequest.vue';
import EmptyEvent from './types/EmptyEvent.vue';
import EmployeeUpdated from './types/EmployeeUpdated.vue';
import ChangePrice from './types/ChangePrice.vue';

export default {
  components: {
    AddedFile,
    RequestReschedule,
    RequestClose,
    ClientAcceptedJob,
    AppTimelineItem,
    ContactDetached,
    InteractionItemUser,
    ContactAttached,
    ContactUpdated,
    EntityUpdated,
    MoveRequest,
    EmptyEvent,
    EmployeeUpdated,
    RoleUpdated,
    ChangePrice,
    RemovedFile,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { data } = toRefs(props);
    const { t } = useI18n();

    // const changes = data.value?.additional_data?.changes;
    // const contactEvents = ['1006', '2006'];

    const eventLevels = {
      1: 'primary',
      2: 'success',
      3: 'danger',
      4: 'primary',
      5: 'primary',
      6: 'primary',
      7: 'danger',
      8: 'primary',
      9: 'danger',
      10: 'secondary',
      11: 'primary',
      12: 'warning',
      1001: 'primary',
      1002: 'primary',
      1003: 'danger',
      1004: 'primary',
      1005: 'danger',
      1006: 'warning',
      1010: 'secondary',
      1011: 'primary',
      2001: 'primary',
      2002: 'warning',
      2003: 'danger',
      2004: 'primary',
      2005: 'danger',
      2006: 'warning',
      2010: 'secondary',
      3001: 'primary',
      4001: 'success',
      4002: 'danger',
    };
    const variant = eventLevels[`${data.value.event}`] || 'secondary';

    const eventsTypes = [
      {
        name: 'CONTACT_ATTACHED',
        events: [1004, 2004],
      },
      {
        name: 'CONTACT_DETACHED',
        events: [1005, 2005],
      },
      {
        name: 'CONTACT_UPDATED',
        events: [1006, 2006],
      },
      {
        name: 'ENTITY_UPDATED',
        events: [2002, 12],
      },
      {
        name: 'MOVE_REQUEST',
        events: [1],
      },
      {
        name: 'EMPLOYEE_UPDATED',
        events: [8, 9],
      },
      {
        name: 'CLIENT_ACCEPTED_JOB',
        events: [6],
      },
      {
        name: 'ROLE_UPDATED',
        events: [3001],
      },
      {
        name: 'CHANGE_PRICE',
        events: [5],
      },
      {
        name: 'REQUEST_CLOSE',
        events: [3],
      },
      {
        name: 'REQUEST_RESCHEDULE',
        events: [4],
      },
      {
        name: 'FILE_ATTACHED',
        events: [4001],
      },
      {
        name: 'FILE_DETACHED',
        events: [4002],
      }
    ];

    const eventType = eventsTypes.find((i) => i.events.includes(data.value.event))?.name;
    const eventEntity = eventsTypes.find((i) => i.events.includes(data.value.event))?.entity;

    return {
      t,
      // changes,
      // contactEvents,
      // removeClientEvents,
      variant,
      eventType,
      eventEntity,
    };
  },
};
</script>

<style lang="sass">
.interaction
  text-align: left
  &-header
    display: flex
    align-items: center
    justify-content: space-between
    gap: 16px
  &-changes
    opacity: .7
</style>
