<template>
  <div class="search-group-contact">
    <div class="search-group-contact-item">
      <FeatherIcon icon="UserIcon" />
      <div>{{ data.name }}</div>
    </div>
    <div class="search-group-contact-item">
      <FeatherIcon icon="BriefcaseIcon" />
      <div>{{ data.position }}</div>
    </div>
    <div class="search-group-contact-item">
      <FeatherIcon icon="PhoneIcon" />
      <div>{{ data.phone }}</div>
    </div>
    <div class="search-group-contact-item">
      <FeatherIcon icon="MailIcon" />
      <div>{{ data.email }}</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: Object,
  },
  setup() {
    const formatName = (obj) => obj.name || obj.title || [obj.firstname, obj.surname, obj.patronymic].join(' ');

    return {
      formatName,
    };
  },
};
</script>

<style lang="sass" scoped>
.search-group-contact
  display: flex
  align-items: center
  // gap: 24px
  flex-wrap: wrap
  max-width: 400px
  &-item
    display: flex
    gap: 8px
    align-items: center
    flex: 0 0 50%
</style>
