export default [
  {
    title: 'pageTitle.home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'pageTitle.requests',
    route: 'requests',
    icon: 'DollarSignIcon',
  },
  // {
  //   title: 'pageTitle.tasks',
  //   route: 'tasks',
  //   icon: 'CalendarIcon',
  // },
  {
    title: 'pageTitle.clients',
    route: 'clients',
    icon: 'BriefcaseIcon',
  },
  {
    title: 'pageTitle.facilities',
    route: 'facilities',
    icon: 'FolderIcon',
  },
  {
    title: 'pageTitle.employees',
    route: 'employees',
    icon: 'UsersIcon',
  },
  {
    title: 'pageTitle.equipment',
    route: 'equipment',
    icon: 'PackageIcon',
  },
  {
    title: 'pageTitle.checklists',
    route: 'checklists',
    icon: 'CheckSquareIcon',
  },
  {
    title: 'pageTitle.scheduler',
    route: 'scheduler',
    icon: 'RepeatIcon',
  },
  // {
  //   title: 'pageTitle.analytics',
  //   route: 'analytics',
  //   icon: 'ActivityIcon',
  // },
  {
    title: 'pageTitle.settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
];
