<template>
    <b-row
            v-if="textBreadcrumbs.breadcrumb || textBreadcrumbs.pageTitle"
            class="content-header"
    >
        <!-- Content Left -->
        <b-col
                class="content-header-left mb-2"
                cols="12"
                md="9"
        >
            <b-row class="breadcrumbs-top">
                <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0">
                        {{ $t(textBreadcrumbs.pageTitle) }}
                    </h2>
                    <div class="breadcrumb-wrapper">
                        <b-breadcrumb>
                            <b-breadcrumb-item to="/">
                                <feather-icon
                                        icon="HomeIcon"
                                        size="16"
                                        class="align-text-top"
                                />
                            </b-breadcrumb-item>
                            <b-breadcrumb-item
                                    v-for="item in textBreadcrumbs.breadcrumb"
                                    :key="item.text"
                                    :active="item.active"
                                    :to="item.to"
                            >
                                {{ item.entryId ? $t(item.text) + ` ${item.entryId}`
                                    : $t(item.text) }}
                            </b-breadcrumb-item>
                        </b-breadcrumb>
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <Interactions />
    </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import {computed, ref, watch} from '@vue/composition-api';
import {useRouter} from '@core/utils/utils';
import Interactions from '@/components/interactions/Interactions.vue';
import {useStore} from '@/hooks/useStore';

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    Interactions,
  },
  setup() {
    const {router} = useRouter();
    const store = useStore();
    const textBreadcrumbs = ref(router.currentRoute.meta);
    const refreshMeta = computed(() => store.state.app.refreshMeta);
    watch(refreshMeta, () => {
      textBreadcrumbs.value = router.currentRoute.meta;
      console.log(router.currentRoute.meta.breadcrumb.at(1));
    })
    return {
      textBreadcrumbs, refreshMeta
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.textBreadcrumbs = route.meta;
      },
      deep: true,
    },
  },
};
</script>

<style lang="sass">
.client-interaction
    cursor: pointer
</style>
